<template>
    <v-container>
        <h1>404</h1>
        <h2>Page not Found</h2>
        <h4>We're sorry, we couldn't find the page you requested.</h4>
    </v-container>
</template>
<script>
    export default {
        name: 'PageNotFound'
    }
</script>