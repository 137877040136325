import * as edcConstantObj from './constants.js'
import * as edcHierarchyObj from './hierarchy.js'
var graphForBusinessObject = edcConstantObj.GRAPH_FOR_BUSINESS_OBJECT
var graphForProcessDesigner = edcConstantObj.GRAPH_FOR_PROCESS_DESIGNER
var graphForDataSelection = edcConstantObj.GRAPH_FOR_DATA_SELECTION

export function getRevisedCellsForAug2020Release(cells,graphFor,tableFilters){
	// first seprate elements and the links.
	let elements = []
	let elementWidth = edcConstantObj.BUSINESS_OBJECT_TABLE_WIDTH
	let elementHeight = edcConstantObj.BUSINESS_OBJECT_TABLE_HEIGHT
	if(graphFor === graphForBusinessObject || graphFor === graphForDataSelection){
		elements = edcHierarchyObj.createBusinessObjHierarchy(cells)
	}
	else if(graphFor === graphForProcessDesigner){
		elementWidth = edcConstantObj.PROCESS_DESIGNER_SHAPE_WIDTH
		elementHeight = edcConstantObj.PROCESS_DESIGNER_SHAPE_HEIGHT
		cells.forEach(element=>{
			if(element.type === 'link')
			return
			elements.push(element)
		})
	}
	let links = []
	let special_cell = {}
	let zippedCells = []


	cells.forEach(cell=>{
		if(cell.type != 'link')
			return
		links.push(cell)
	})

	elements.forEach(function(element,index){
		zippedCells.push(zipAug2020Element(element,elementWidth, elementHeight,graphFor,tableFilters))

	})

	links.forEach(link=>{
		zippedCells.push(zipAug2020Link(link,graphFor))
	})

	return zippedCells
}

export function zipAug2020Element(element,elementWidth,elementHeight,graphFor,tableFilters={}){
	let zippedElement =  {
		"id":element.id,
		"positionX":element.position.x,
		"positionY":element.position.y,
		"width":elementWidth,
		"height":elementHeight,
		"is_zipped_element":true
	}

	if(graphFor === graphForBusinessObject || graphFor === graphForDataSelection){
	zippedElement['table_name']=element.table_name
	if(element.table_type)
		zippedElement['table_type']=element.table_type
	else
		zippedElement['table_type']='table'
	zippedElement['table_description']=element.table_name
	if(element.is_special_cell)
		zippedElement['is_special_cell']=true
	zippedElement['has_filter']=false
	if(tableFilters[element.id])
		zippedElement['has_filter']=true
	}
	if(graphFor === graphForProcessDesigner){
		zippedElement['steptype']=element.steptype
		zippedElement['step_data']=element.step_data
		zippedElement['bussinessObjectName']=element.bussinessObjectName
		zippedElement['policy_id']=element.policy_id
		zippedElement['bus_id']=element.bus_id
		zippedElement['stepname']=element.stepname
	}
	if(graphFor === graphForDataSelection){
		if(element.table_type === 'step' || element['is_drv_table'] === true
			|| element['is_drv_table'] === "true" 
			||(element.table_name && element.table_name.indexOf(" ")>=0)){
			zippedElement['is_drv_table'] = true
			zippedElement['table_type'] = 'step'
		}
	}

	return zippedElement
}

// is_zipped_link used alogn with type because we are not sure are we going to zip all links or not
export function zipAug2020Link(link,graphFor){
	let zippedLink = {
		'id':link.id,
		'type':'link',
		'source':{"id":link.source.id},
		'target':{"id":link.target.id},
		'is_zipped_link':true
	}
	if(graphFor ===graphForBusinessObject){
		if(link.labels.length === 2){
			zippedLink['label1'] = link.labels[0].attrs.text.text
			zippedLink['label2'] = link.labels[1].attrs.text.text
		}
		else{
			zippedLink['label1'] = "1"
			zippedLink['label2'] = "1"
		}
		zippedLink['has_flattening_restriction'] = link.has_flattening_restriction
	}
	return zippedLink
}
