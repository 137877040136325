'use strict'
import * as edcGraphObj from './graph.js'
const joint = require('jointjs')

var edcGraphLink =  new joint.dia.Link({
        router: { name: 'manhattan' },
        connector: { "name": 'rounded',args: {
            radius: 5
        } },
        attrs: {
            '.connection': {
            stroke: '#333333',
            'stroke-width': 2
            },
            '.marker-target': {
                fill: '#333333',
                d: 'M 10 0 L 0 5 L 10 10 z'
            },
            '.link-tools': {
                display: 'none'
            },
            '.marker-arrowheads':{
                display: 'none'
            }
        },
        labels: [ { position: 0.4, attrs: { text: { text: '',fill:"#0000FF" } } },
        { position: 0.6, attrs: { text: { text: '' ,fill:"#0000FF"} } }  ],
        smooth: true
    })

export function getDefaultLinkProperies(defaultData={}){
    let defaultLink = edcGraphLink.clone()
    if(defaultData.id){
        defaultLink.set('id',defaultData.id)
    }
    if(defaultData.source)
        defaultLink.source(defaultData.source)
    if(defaultData.target)
        defaultLink.target(defaultData.target)
    return defaultLink
}

export function RestrictCyclicConnection(graph, graphlib, linkView) {
    return false
    var link = linkView.model
    if (graphlib.alg.findCycles(graph.toGraphLib()).length > 0) {
        link.remove();
        return true
            // show some error message here
        }
        return false
    }

/*
this method will check if source link connect target element morethan 1 time.
if it is,then delete newly draw line
*/
export function ValidateOutgoingLink(graph, linkView) {
    var link = linkView.model
    if (link) {
        var source = link.get('source')
        var current_target = link.get('target')
        var source_outgoing_links = graph.getConnectedLinks(source, { outbound: true })
        if (source_outgoing_links && source_outgoing_links.length > 1) {
            for (var i = 0; i < source_outgoing_links.length; i++) {
                var loop_link = source_outgoing_links[i]
                var loop_link_target = loop_link.get('target')
                if (loop_link.id != link.id && current_target.id === loop_link_target.id) {
                    link.remove()
                    return false
                }
            }
        }
    }
    return true
}

/*
This function mainly used for business object where element wont have
more than one incoming link
*/
export function ValidateIncomingLink(graph, linkView) {
    var link = linkView.model
    if (link) {
        var current_source = link.get('source')
        var target = link.get('target')
        var target_incoming_links = graph.getConnectedLinks(target, { inbound: true })
        if (target_incoming_links && target_incoming_links.length > 1) {
            link.remove()
            return false
        }
    }
    return true
}

export function GetHierarchyName(graph,child_id, HierarchyName=''){
        var element = graph.getCell(child_id)
        var child_tbl_name = element.prop('table_name').trim()
        var links = graph.getConnectedLinks(element, { inbound: true })
        if(links.length > 0){
        /* if link found then there is only one link between to cells in case of 
        business object.So we can directly fetch details at 0 level
        */
        var parent_cell = graph.getCell(links[0].get('source'))
        var parent_tbl_name = parent_cell.prop('table_name').trim()
        if(!HierarchyName)
            HierarchyName = child_tbl_name
        HierarchyName = HierarchyName + ' - ' + parent_tbl_name
        return GetHierarchyName(graph,parent_cell.id,HierarchyName)
    }
    return HierarchyName
}


export function getRelatedLinks(element,graph,graphFor,isWithZip=true){
    // in case of ID passed
    if(typeof(element) === 'string')
        element = graph.getElement(element)
    let relatedLinks = graph.getConnectedLinks(element)
    if(!isWithZip)
        return relatedLinks
    let zippedLinks = []
    for(let i=0;i<relatedLinks.length;i++)
        zippedLinks.push(edcGraphObj.zipLink(relatedLinks[i],graphFor))
    return zippedLinks
}