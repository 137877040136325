<template> 
<div id="addenvironment">
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <router-link to="/erpacceleratorlist">Manage ERP Accelerator</router-link>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-container>
      <v-form v-model="valid" ref="form">
        <v-card elevation-1>     
          <v-container class="EDC-Container">
           <v-col cols="12" class="EDC-Col">
                    <v-row  class="EDC-Row" style="padding-top:15px !important">
                        <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
                          <v-text-field label="Name" v-model="name" required :rules="namedRules" autocomplete="off" dense></v-text-field>
                          <!-- <input type="text" autofocus  :rules="namedRules" v-model="name" @blur="untitle_environment" ref='envname'/> -->
                        </v-col>
                        <v-col cols="1"></v-col>
                        <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
                          <label class="v-label" style="float:left" dense>ERPs</label>
                        </v-col>
                        <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
                          <v-radio-group v-model="accelerator_value" required hide-details row dense class="EDC-UIComponent">
                            <v-radio class="customRadio" :color="colorCode" v-for="(n, index) in acceleratorList" :key="index" :label="n.name"  :value="n.id" dense></v-radio>
                          </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row EDC-RowsSpacing" style="padding-left:5px !important">
                        <v-col cols="4" class="EDC-Col EEDC-ColsSpacing EDC-ColTopPadding">
                          <label class="v-label" style="float:left" dense>Select Database Type</label>
                        </v-col>
                        <v-col cols="8" class="EDC-Col EEDC-ColsSpacing EDC-ColTopPadding">
                          <v-radio-group v-model="database_type" required hide-details row @change="get_data_source_by_db(database_type);" class="EDC-UIComponent" dense>
                            <v-radio class="customRadio" :color="colorCode" v-for="(n, index) in database_type_list" :key="index" :label="n.name" :value="n.id" dense></v-radio>
                          </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row EDC-RowsSpacing">
                        <v-col cols="4" class="EDC-Col EDC-ColsSpacing EDC-ColTopPadding">
                          <v-autocomplete clearable autocomplete :items="engine_data_source_list" item-text="datasource_name" item-value="datasource_id" v-model="object_librarian_datasource" label="Select Object Librarian Datasource" 
                           class="controlsmargin"  hide-details dense></v-autocomplete>
                        </v-col>
                        <v-col cols="4" class="EDC-Col EDC-ColsSpacing EDC-ColTopPadding">
                          <v-autocomplete clearable autocomplete :items="engine_data_source_list" item-text="datasource_name" item-value="datasource_id" v-model="dictionary_data_source" 
                           label="Select Dictionary Datasource" class="controlsmargin" hide-details dense></v-autocomplete>
                        </v-col>
                        <v-col cols="4" class="EDC-Col EDC-ColsSpacing EDC-ColTopPadding">
                          <v-autocomplete clearable autocomplete :items="engine_data_source_list" item-text="datasource_name" item-value="datasource_id" v-model="control_datasource" 
                           label="Select Control Datasource" class="controlsmargin" hide-details dense></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
                        <!-- <v-btn outline @click="validate_env" :color="outlineColor" :loading="validation_loding" :disabled="validation_loding" @click.native="loader = 'loading'" style="border-radius:10px">Validate</v-btn> -->
                        <vc-button itemText="Save" @click.native="submit"></vc-button>
                        <vc-button itemText="Cancel" @click.native="clear"></vc-button>
                    </v-row>
                </v-col>
          </v-container>
        </v-card>
      </v-form>
    </v-container>
</div>
</template>
<style scoped>
.customCheckbox >>> label{
  top: 5px !important;
}
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 20px;
}
.customRadio>>> label{
  top: 5px !important;
}
.controlsmargin{
  margin-left:5px;
  margin-right:5px;
}

.EDC-UIComponent{
    margin: 0px !important;
    padding: 0px !important;
}

.EDC-TopRow {
  padding-top:20px !important
}

.EDC-ColTopPadding {
  padding-top:20px !important
}

.EDC-ColTextarea {
  padding-top:12px !important
}
</style>
<script>
import { post as postToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import {SERVER_ERROR} from '../../data/client_message.js'
import { GETDATASOURCEBYBDTYPE} from '../../data/url_constants.js'
import {COLOR_CODE, BTN_COLOR, CHECKBOX_COLOR} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  data () {
    return {
      tabIndex:2,
      outlineColor:BTN_COLOR,
      colorCode:COLOR_CODE,
      checkboxColor:CHECKBOX_COLOR,
      SourceToTargetDbLinkValue : '',
      TargetToSourceDbLinkValue: '',
      valid: true,
      snackbar:false,
      snackbartext:'',
      colorValue:'error',
      snackbartimeout: Snackbar_Default_Timeout,
      loader: "loading",
      loading: false,
      validation_loding:false,
      name:"Untitled ERP Accelerator",
      environment_type:"",
      database_type:"oracle",
      database_type_list:[{id:"ms_sql",name:"MS-SQL"},
                              {id:"oracle",name:"Oracle"},
                              {id:"db2",name:"DB2 LUW"},
                              {id:"db2i",name:"DB2 i-series"}],
      source_data_source:"",
      target_data_source:"",
      engine_data_source:"",
      target_engine_data_source: "",
      catalog_data_source:"",
      dblocation:"",
        // mapped_agent: "",
        description: "",
        environment_id:"undefined",
        environment_type_list: [],
        database_location_list: [],
        source_data_source_list:[],
        target_data_source_list:[],
        engine_data_source_list:[],
        catalog_data_source_list:[],
        catalog_datasource_list:[],
        dictionary_data_source: "",
        data:'',
        db_type:'',
        env_config_list: [],
        namedRules:[
        v => !!v || 'Name is required'
        ],
        datasourceRules:[
        v => !!v || 'Database Source is required'
        ],ionaryRules:[
        v => !!v || 'Dictionary datasource is required.'
        ],
        agent_list: [],
        calendar_list: [],
        calendar: '',
        calendarRules: [
        v => !!v || 'Calendar is required.'
        ],
        tableSpaceList:[],
        selected_table_space:'',
        selected_index_space:'',
        acceleratorList:[{"name":"None","id":"none"},{"name":"JD Edwards E1","id":"jde_ed_e1"},{"name":"E-Business Suite","id":"e_bus_suite"}],
        accelerator_value : "none",
        object_librarian_datasource:"",
        control_datasource:"",
        disabledTargetEngine:false, // we are going to disabled target engine, when database location is same_database for all database or save_server in case of ms_sql.
      }
    },
    mounted (){
      
      if(this.$route.params.id)
        this.getAcceleratorDetails()
      else
        this.get_data_source_by_db(this.database_type)      
  },
methods: {
    getAcceleratorDetails() {
      var _this = this
      var data = {"id": this.$route.params.id,
                  "client_id":this.$session.get('client_id')}
        postToServer(_this, config.JDE_FORMATER_URL + '/get_accelerator_details', data).then(response => {
                var data = response
                _this.get_data_source_by_db(data.database_type)
                if(_this.$route.params.id)
                  {
                    _this.name=data.accelerator_name
                    _this.accelerator_value= data.accelerator_details.erp_type
                    _this.object_librarian_datasource= data.accelerator_details.object_librarian_datasource
                    _this.dictionary_data_source = data.accelerator_details.dictionary_datasource
                    _this.control_datasource = data.accelerator_details.control_datasource
                    _this.database_type=data.database_type
                     
                  }

           }).catch(error_response => {
            if(error_response){
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = error_response;
              _this.loader = null 
            }
            else {
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = SERVER_ERROR;
            }
        })
     },
    get_data_source_by_db(db_type,response_data){
      var _this = this
      this.engine_data_source_list = []
      var user_input = {
        'client_id': this.$session.get('client_id'),
        'database_type': db_type,
      }
      var data_source_endpoint = config.DATA_SOURCE_URL + GETDATASOURCEBYBDTYPE
      postToServer(this, data_source_endpoint, user_input, true).then(datasourceResponse => {
       _this.engine_data_source_list = datasourceResponse.datasource
         console.log(_this.engine_data_source_list)
      }).catch(error_response => {
        if(error_response){
            
          }
          else {
            
          }
        });
    },

    submit () {
      if (this.$refs.form.validate()) 
      {
        if (this.$route.params.id) {
          this.update_accelerator()
        } else {
          this.add_accelerator()
        }
      }
    },
      add_accelerator(){
        this.loading = true
        var add_accelerator_details = {
            'client_id': this.$session.get('client_id'),
            'created_by': this.$session.get('email'),
            'accelerator_name': this.name,
            'database_type': this.database_type,
            'erp_type':this.accelerator_value,
            'dict_das': this.dictionary_data_source ? this.dictionary_data_source : '',
            'obj_lib_das':this.object_librarian_datasource ? this.object_librarian_datasource : '' ,
            'ctl_das':this.control_datasource ? this.control_datasource : '',
        }

        var add_accelerator_endpoint = config.JDE_FORMATER_URL + "/add_accelerator"
        postToServer(this, add_accelerator_endpoint, add_accelerator_details).then(environmentResponse => {
          if(environmentResponse){
            this.$router.push("/erpacceleratorlist")
          }
        }).catch(error_response => {
          this.loading = false
          if(error_response){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = error_response;
          }
          else {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = 'environment';
          }
        });
      },

      update_accelerator(){
        this.loading = true
        var update_accelerator_data = {
         'client_id': this.$session.get('client_id'),
         'modified_by': this.$session.get('email'),
         'id': this.$route.params.id,
         'accelerator_name': this.name,
         'database_type': this.database_type,
          'erp_type':this.accelerator_value,
          'dict_das': this.dictionary_data_source ? this.dictionary_data_source : '',
          'obj_lib_das':this.object_librarian_datasource ? this.object_librarian_datasource : '' ,
          'ctl_das':this.control_datasource ? this.control_datasource : '',
        }
        var update_accelerator_endpoint = config.JDE_FORMATER_URL + "/update_accelerator"
        postToServer(this, update_accelerator_endpoint, update_accelerator_data).then(environmentResponse => {
          if(environmentResponse){
           environmentResponse.test
           this.$router.push("/erpacceleratorlist")
         }
       }).catch(environmentError => {
        this.loading = false
        if(environmentError){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = environmentError;
        }
        else {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'environment';
        }
      });
     },
   clear () {
    this.$refs.form.reset()
    this.$router.push('/erpacceleratorlist')
  }
}
}

</script>