import {separate_operator, stripDateFormat} from '@/methods/commonFunc.js'

var moment = require("moment-timezone")
export default function(self, date){
    // let timeZone = self.$session.get('time_zone_preference');
    // let format = new Date(date).toLocaleString("en-US", {timeZone: timeZone});
    return date;
}

export function getJDEDate(text,format='DD-MM-YYYY'){
	// first check valid date
	text = separate_operator(text)
	if(!text || text==='0')
		return "0"
	text = getTextWithProperNumbers(text,format)
	let date = moment(text,format,true)
	let striped_date_format = stripDateFormat(format)
	let format_list = []
	if(striped_date_format === "DDMMYYYY")
		format_list = ['DD-MM-YYYY','DD/MM/YYYY','DDMMYYYY','MMMM D,Y','MMM D,Y']	
	else if(striped_date_format === "MMDDYYYY")
		format_list = ['MM/DD/YYYY','MM-DD-YYYY','MMDDYYYY','MMMM D,Y','MMM D,Y']
	else if(striped_date_format === "YYYYMMDD")
		format_list = ['YYYY/MM/DD','YYYY-MM-DD','YYYYMMDD','MMMM D,Y','MMM D,Y']
	else if(striped_date_format === "YYYYDDMM")
		format_list = ['YYYY/DD/MM','YYYY-DD-MM','YYYYDDMM','MMMM D,Y','MMM D,Y']
	else
		format_list = ['MMMM D,Y','MMM D,Y']
	// format_list = ['DD-MM-YYYY','YYYY/MM/DD','YYYY/DD/MM','DD/MM/YYYY','MM/DD/YYYY','MM-DD-YYYY','YYYY-MM-DD','YYYY-DD-MM','YYYYMMDD','YYYYDDMM','DDMMYYYY','MMDDYYYY','MMMM D,Y']
	for(let i=0; i <format_list.length; i++){
		date = moment(text,format_list[i], true)
		if(date.isValid()){
			break
		}
		if(i === format_list.length-1){
			if(!date.isValid()){
				alert('input date is not valid')
				return false
			}
		}
	}
	let year = date.year()
	let century = Math.floor(year/100)
	let jdeC = century-19
	let onlyYY = year%100
	let dayOfYear = date.dayOfYear()
	let jdedate = ""+jdeC+_.padStart(onlyYY,2,'0')+_.padStart(dayOfYear,3,'0')
	let numericDate = parseInt(jdedate);
	return numericDate

}

export function convertToUserNativeDate(text,user_format='DD-MM-YYYY'){
	let format='DD-MM-YYYY'
	let operator = separate_operator(text,true)
	text = separate_operator(text)
	if(!text || text === 0 || text === "0"){
		if (operator)
			return operator+"0"
		return "0"
	}
	text = getTextWithProperNumbers(text,user_format)
	let date = moment(text,format,true)
	let format_list = ['DD-MM-YYYY','YYYY/MM/DD','YYYY/DD/MM','DD/MM/YYYY','MM/DD/YYYY','MM-DD-YYYY','YYYY-MM-DD','YYYY-DD-MM','YYYYMMDD','YYYYDDMM','DDMMYYYY','MMDDYYYY','MMMM D,Y','MMM D,Y']
	// here we are giving preference to the user format. because MM/DD/YYYY and DD/MM/YYYY will create issue if date value < 12
	if(user_format && format_list[0]!= user_format)
		format_list.splice(0,0,user_format)
	for(let i=0; i <format_list.length; i++){
		date = moment(text,format_list[i], true)
		if(date.isValid()){
			format = format_list[i]
			break
		}
		
	}
	// moment(text).format(format)
	// moment(text, format).format(user_format)
	let moment_format_date = moment(text, format).format(user_format)
	if(moment_format_date === 'Invalid date')
		moment_format_date = text
	if (operator)
		return ""+ operator + moment_format_date
	else
	return moment_format_date
}

function getTextWithProperNumbers(text, user_format){
	/*
		In this function we will check DD has 2 value, MM has 2. For YYYY we are relying on user only.
		Expectation is user atleast put yyyy properly.Because you cannot decide year century what user want.
		NOTE: HERE I HAVE NOT CONSIDER USER FORMAT. IF YOU ARE GOING TO CONSIDER YEAR THEN USER FORMAT REQ.
		1st try to split with - else then '/'
	*/
	text = text.trim()
	let operator = '-'
	let splitArray = text.split('-')
	if(splitArray.length < 3){
		operator ='/'
		splitArray = text.split('/')
	}
	if(splitArray.length<3)
		return text
	if(splitArray[0].length ===1)
		splitArray[0] = splitArray[0].trim().padStart(2,'0')
	if(splitArray[1].length ===1)
	splitArray[1] = splitArray[1].trim().padStart(2,'0')
	if(splitArray[2].length ===1)
	splitArray[2] = splitArray[2].trim().padStart(2,'0')
	return splitArray[0]+operator+splitArray[1]+operator+splitArray[2]
}

export function convertDateToJde(dateValue,userFormat){
	if(!dateValue)
		return '0'
	let momentObj = moment(dateValue,userFormat)
	let dayOftheYear = momentObj.dayOfYear().toString().padStart(3,'0')
	let year = momentObj.year().toString().substring(2,4)
	let century = momentObj.year().toString().substring(0,2)
	let yyddd = year+''+dayOftheYear
	let jdeDate = '1'+ yyddd
	if(century === '19')
		jdeDate = '0'+ yyddd
	return jdeDate	
}

export function getTodaysDate(userFormat){
	return moment().format(userFormat);
}