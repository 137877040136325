<template>
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="EDCMessage" top right>
        {{ snackbartext }}
        <v-btn dark text v-if="showSnackbarClose" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
</template>

<script>
    export default {
        name: 'edcsnackbar',

        props: {
        	snackbar: {
        		type: Boolean,
        		default: false
        	},
        	colorValue: {
        		type: String,
        		default: 'success'
        	},
        	snackbartimeout: {
        		type: Number,
        		default: 5000
        	},
        	snackbartext: {
        		type: String,
        		default: ''
        	},
        	showSnackbarClose: {
        		type: Boolean,
        		default: false
        	}
        },

        watch: {
        	'snackbar': {
        		handler(newValue) {
        		}
        	},
        	'colorValue': {
        		handler(newValue) {
        		}
        	},
        	'snackbartimeout': {
        		handler(newValue) {
        		}
        	},
        	'snackbartext': {
        		handler(newValue) {
        		}
        	},
        	'showSnackbarClose': {
        		handler(newValue) {
        		}
        	}
        }
    }
</script>

<style scoped>

</style>