export const GRID_SIZE = 5
export const GRID_COLOR = '#FFFFFF'
export const LINK_RELATIONSHIP = [
								{id: "1:1",name: "1:1"},
								{id: "1:M",name: "1:M"},
								{id: "M:1",name: "M:1"},
								{id: "M:M",name: "M:M"}]

export const CONTEXT_MENU_ITEMS = [
									{"id":"cut","name":"Cut"},
									{"id":"copy","name":"Copy"},
									{"id":"paste","name":"Paste"},
									{"id":"delete","name":"Delete"},
									{"id":"rename","name":"Rename"}
								]

export const BUSINESS_OBJECT_TABLE_WIDTH = 120
export const BUSINESS_OBJECT_TABLE_HEIGHT = 60
export const BUSINESS_OBJECT_TABLE_BORDER_RX = 10
export const BUSINESS_OBJECT_TABLE_NAME_REFY = 0

export const BUSINESS_OBJECT_TABLE_DESCRIPTION_REFY = 75

export const BUSINESS_EDC_CELL_TYPE = 'business_table'
export const BUSINESS_EDC_CELL_CONTAINER_TYPE = 'business_table_container'
export const BUSINESS_EDC_CELL_KEY_TYPE = 'business_keys'

export const BUSINESS_OBJECT_KEYS_NAME = 'Business Object Keys'
export const BUSINESS_OBJECT_KEYS_CONTAINER_TYPE = 'business_key_table_container'

export const CENTER_OF_BO_GRAPH_PAPER = ((window.innerWidth-(window.innerWidth*.20))/2-((BUSINESS_OBJECT_TABLE_WIDTH)/2))-25 // 25 for adjusting the scroll and left/right side space

export const GRAPH_EVENTS_FOR_REDESIGN = ['add','remove','change','redesign','cellpropchange']

export const GRAPH_FOR_BUSINESS_OBJECT = 'business_object'
export const GRAPH_FOR_PROCESS_DESIGNER = 'process_designer'
export const GRAPH_FOR_DATA_SELECTION = 'data_selection'

export const PROCESS_DESIGNER_SHAPE_BORDER_RX = 12
export const PROCESS_DESIGNER_SHAPE_WIDTH = 80
export const PROCESS_DESIGNER_SHAPE_HEIGHT = 80
export const PROCESS_DESIGNER_EDC_CELL_TYPE = 'process_designer_step'
export const PROCESS_DESIGNER_EDC_CELL_CONTAINER_TYPE = 'process_designer_step_container'
export const SELECTION_STEP = 'Selection'
export const MINUS_STEP = 'Minus'
export const MERGE_STEP = 'Merge'
export const ARCHIVE_STEP = 'Archival'
export const COPY_STEP = "Copy"
export const PURGE_STEP = "Purge"
export const DUPLICATE_STEP = "Duplicate"
export const PROCEDURE_STEP = 'Procedure'
export const PROCESS_DESIGNER_DESCRIPTION_REFY = 100

export const CELL_HIGHLIGHTER =  {
		highlighter: {
			name: 'stroke',
			options: {
				padding: 0,
				rx: 9,
				ry: 9,
				attrs: {
					'stroke-width': 5,
					stroke: '#FFA500'
				}
			}
		}
	}

