import { render, staticRenderFns } from "./ManageObjRoleNew.vue?vue&type=template&id=1886717c&scoped=true"
import script from "./ManageObjRoleNew.vue?vue&type=script&lang=js"
export * from "./ManageObjRoleNew.vue?vue&type=script&lang=js"
import style0 from "./ManageObjRoleNew.vue?vue&type=style&index=0&id=1886717c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1886717c",
  null
  
)

export default component.exports