const joint = require('jointjs')
import * as shapeIcons from './icons.js'
import * as constantObj from './constants.js'
import * as anonymousObj from './anonymousFunctions.js'

var edcBorderImage = new joint.shapes.standard.BorderedImage({
	attrs : {
		root:{
			magnet:false
		},
        image : {
            width : 16,
            height : 16,
        },
        border:{

        },
        label:{
        	text:{
        		textWrap:{
        			width: -10, // reference width minus 10
        			ellipsis: true // could also be a custom string, e.g. '...!?'
        		}
        	}
        }
    }
});

var edcWrapperRect  = new joint.shapes.standard.Rectangle({
	attrs:{
		body: {
    		fill: 'transparent',
    		stroke:{
	           	width:0
	        },
		},
		 border:{
		 	fill: 'transparent'
        },
		label: {
    
		}
	}
});

var edcBORect  = new joint.shapes.standard.Rectangle({
	attrs:{
		body: {
    		fill: '#B0B0B0',
    		stroke:{
	           	width:1
	        },
		},
		label: {
    
		}
	}
});

export function getRubberBandRect(){
	var rect = new joint.shapes.standard.Rectangle();
	rect.prop('is_rubber',true)
	rect.attr({
    	body: {
        	fill: 'transparent',
    	},
    	label: {

		}
	});
	rect.attr("rect/strokeDasharray", "5,5");
	return rect
}

export function getEdcTableShapeWithWrapper(tablesProps){
	if(!tablesProps.has_filter)
		tablesProps.has_filter = false

	let edcTableShape = edcBorderImage.clone()
	let snappedX = anonymousObj.getNearestGridSnapPosition(tablesProps.positionX)
	let snappedY = anonymousObj.getNearestGridSnapPosition(tablesProps.positionY)
	edcTableShape.resize(constantObj.BUSINESS_OBJECT_TABLE_WIDTH, constantObj.BUSINESS_OBJECT_TABLE_HEIGHT);
	edcTableShape.position(snappedX,snappedY)
	edcTableShape.attr('border/rx', constantObj.BUSINESS_OBJECT_TABLE_BORDER_RX);
	edcTableShape.attr('rect/magnet',true);
	edcTableShape.attr('label/title', tablesProps.table_name);
	let fontsize = anonymousObj.getSuitableFontSize(tablesProps.table_name)
	edcTableShape.attr('label/font-size',fontsize)
	edcTableShape.attr('label/text',  anonymousObj.getWrappedText(tablesProps.table_name,60,joint,2,fontsize));
	// edcTableShape.attr('label/text',  tablesProps.table_name);
	edcTableShape.attr('image/title', tablesProps.table_name);
	edcTableShape.attr('label/fill', '#FFFFFF');
	edcTableShape.attr('label/refY', constantObj.BUSINESS_OBJECT_TABLE_NAME_REFY);
	edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 encodeURIComponent(shapeIcons.getTableWithoutFilter()));

	edcTableShape.prop('table_name',tablesProps.table_name)
	edcTableShape.prop('table_type',tablesProps.table_type)
	edcTableShape.prop('table_description',tablesProps.table_description)
	edcTableShape.prop('edc_cell_type',constantObj.BUSINESS_EDC_CELL_TYPE)
	edcTableShape.prop('is_embed',true)
	edcTableShape.prop('has_filter',tablesProps.has_filter)
	edcTableShape.prop('applicable_for_REDOUNDO',tablesProps.applicable_for_REDOUNDO)

	if(tablesProps.addDrvProp){
		// edcTableShape.attr('label/text',anonymousObj.getWrappedText(tablesProps.table_description,
		// constantObj.BUSINESS_OBJECT_TABLE_WIDTH,joint,2))
		if(tablesProps.table_type === 'step')
			edcTableShape.prop('is_drv_table', true)
		else
			edcTableShape.prop('is_drv_table',false)
	}
	if(tablesProps.id)
		edcTableShape.set('id',tablesProps.id)
	if(tablesProps.has_filter)
		edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' 
			+encodeURIComponent(shapeIcons.getTableWithFilter()));

	let wrapperRect = edcWrapperRect.clone()
	// -5 for more magnet space, if still the complaint about magnet drawing, +(5/2) value into with 
	// position values so then text will get place at center.

	wrapperRect.resize(constantObj.BUSINESS_OBJECT_TABLE_WIDTH, constantObj.BUSINESS_OBJECT_TABLE_HEIGHT);
	wrapperRect.position(snappedX,snappedY)
	wrapperRect.attr('label/text',anonymousObj.getWrappedText(tablesProps.table_description,
		constantObj.BUSINESS_OBJECT_TABLE_WIDTH,joint,2))
	wrapperRect.attr('label/title',tablesProps.table_description)
	wrapperRect.attr("rect/title", tablesProps.table_name);
	wrapperRect.prop('table_name',tablesProps.table_name)
	wrapperRect.prop('table_type',tablesProps.table_type)
	wrapperRect.prop('table_description',tablesProps.table_description)
	wrapperRect.prop('is_wrapper',true)
	// wrapperRect.attr('label/text',tablesProps.table_description)
	wrapperRect.attr('label/refY', constantObj.BUSINESS_OBJECT_TABLE_DESCRIPTION_REFY);
	wrapperRect.attr("rect/rx", constantObj.BUSINESS_OBJECT_TABLE_BORDER_RX);
	wrapperRect.prop('edc_cell_type',constantObj.BUSINESS_OBJECT_KEYS_CONTAINER_TYPE);
	wrapperRect.attr("root/magnet", false);
	wrapperRect.attr('label/font-size', 10);
	wrapperRect.embed(edcTableShape)
	return [edcTableShape,wrapperRect]
}

export function getBusinessObjectKeysCell(tablesProps){
	if(!tablesProps)
		tablesProps = {
			"table_name":constantObj.BUSINESS_OBJECT_KEYS_NAME,
			"table_type":constantObj.BUSINESS_EDC_CELL_KEY_TYPE,
			"table_description":constantObj.BUSINESS_OBJECT_KEYS_NAME,
			"positionX":10,
			"positionY":10
		}
	let edcTableShape = edcBorderImage.clone()
	edcTableShape.resize(constantObj.BUSINESS_OBJECT_TABLE_WIDTH, constantObj.BUSINESS_OBJECT_TABLE_HEIGHT);
	edcTableShape.position(tablesProps.positionX,tablesProps.positionY)
	edcTableShape.attr('border/rx', constantObj.BUSINESS_OBJECT_TABLE_BORDER_RX);
	edcTableShape.attr('rect/magnet',true);
	edcTableShape.attr('label/text', tablesProps.table_name);
	edcTableShape.attr('label/title', tablesProps.table_name);
	edcTableShape.attr('label/fill', '#FFFFFF');
	edcTableShape.attr('label/refY', constantObj.BUSINESS_OBJECT_TABLE_NAME_REFY);
	edcTableShape.attr('label/font-size',10)
	edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 encodeURIComponent(shapeIcons.getBusinessIconWithoutKeys()));
	edcTableShape.attr('image/width', 116);
	edcTableShape.attr('image/height', 56);
	edcTableShape.prop('table_name',tablesProps.table_name)
	edcTableShape.prop('table_type',tablesProps.table_type)
	edcTableShape.prop('table_description',tablesProps.table_description)
	edcTableShape.prop('is_special_cell',true)
	edcTableShape.prop('edc_cell_type',constantObj.BUSINESS_EDC_CELL_KEY_TYPE)
	edcTableShape.prop('is_embed',true)
	edcTableShape.attr('./visibility', 'hidden');

	let wrapperRect = edcWrapperRect.clone()
	// -5 for more magnet space, if still the complaint about magnet drawing, +(5/2) value into with 
	// position values so then text will get place at center.

	wrapperRect.resize(constantObj.BUSINESS_OBJECT_TABLE_WIDTH, constantObj.BUSINESS_OBJECT_TABLE_HEIGHT);
	wrapperRect.position(tablesProps.positionX,tablesProps.positionY)
	wrapperRect.attr('label/text',tablesProps.table_description)
	wrapperRect.attr('label/title',tablesProps.table_description)
	// wrapperRect.attr('label/text',tablesProps.table_description)
	wrapperRect.attr('label/refY', constantObj.BUSINESS_OBJECT_TABLE_DESCRIPTION_REFY);
	wrapperRect.attr("rect/rx", constantObj.BUSINESS_OBJECT_TABLE_BORDER_RX);
	wrapperRect.prop('edc_cell_type',constantObj.BUSINESS_EDC_CELL_CONTAINER_TYPE);
	wrapperRect.attr("root/magnet", false);
	wrapperRect.prop('table_name',tablesProps.table_name)
	wrapperRect.prop('table_type',tablesProps.table_type)
	wrapperRect.prop('table_description',tablesProps.table_description)
	wrapperRect.prop('is_special_cell',true)
	wrapperRect.attr('label/font-size', 10);
	wrapperRect.attr('./visibility', 'hidden');
	if(tablesProps.id){
		edcTableShape.set('id',tablesProps.id)
		edcTableShape.attr('./visibility', 'visible');
		wrapperRect.attr('./visibility', 'visible');
	}
	wrapperRect.embed(edcTableShape)

	return [edcTableShape,wrapperRect]

}

export function addFilterToShape(edcTableShape){
	edcTableShape.prop('has_filter',true)
	edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 encodeURIComponent(shapeIcons.getTableWithFilter()));
	return true
}

export function removeFilterFromShape(edcTableShape){
	edcTableShape.prop('has_filter',false)
	edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 encodeURIComponent(shapeIcons.getTableWithoutFilter()));
	return true
}

export function getDataSelectionShape(){
	let dataSelection = getProcessDesingnerShape(constantObj.SELECTION_STEP,{"positionX":10,"positionY":10})
	return dataSelection
}

export function getMinusShape(){
	let minusStep = getProcessDesingnerShape(constantObj.MINUS_STEP,{"positionX":100,"positionY":10})
	return minusStep
}

export function getMergeShape(){
	let mergeStep = getProcessDesingnerShape(constantObj.MERGE_STEP,{"positionX":190,"positionY":10})
	return mergeStep
}

export function getArchiveShape(){
	let archiveStep = getProcessDesingnerShape(constantObj.ARCHIVE_STEP,{"positionX":10,"positionY":110})
	return archiveStep
}

export function getCopyShape(){
	let copyStep = getProcessDesingnerShape(constantObj.COPY_STEP,{"positionX":100,"positionY":110})
	return copyStep
}

export function getPurgeShape(){
	let purgeStep = getProcessDesingnerShape(constantObj.PURGE_STEP,{"positionX":190,"positionY":110})
	return purgeStep
}

export function getProcedureShape(){
	let procedureStep = getProcessDesingnerShape(constantObj.PROCEDURE_STEP,{"positionX":10,"positionY":210})
	return procedureStep
}

export function getDuplicateShape(){
	let duplicateStep = getProcessDesingnerShape(constantObj.DUPLICATE_STEP,{"positionX":100,"positionY":210})
	return duplicateStep
}

export function getProcessDesingnerShape(type,position,stepProperties={}){
	let processDesingerShape =  edcBorderImage.clone() 
	if(stepProperties.id)
		processDesingerShape.set('id',stepProperties.id)
	processDesingerShape.attr('label/text', '');
	processDesingerShape.attr('border/rx', constantObj.PROCESS_DESIGNER_SHAPE_BORDER_RX);
	processDesingerShape.position(position.positionX,position.positionY);
	processDesingerShape.resize(constantObj.PROCESS_DESIGNER_SHAPE_WIDTH, 
		constantObj.PROCESS_DESIGNER_SHAPE_HEIGHT);

	processDesingerShape.prop('steptype',type)
	processDesingerShape.prop('bus_id','')
	if(stepProperties.bus_id)
		processDesingerShape.prop('bus_id',stepProperties.bus_id)
	processDesingerShape.prop('policy_id','')
	if(stepProperties.policy_id)
		processDesingerShape.prop('policy_id',stepProperties.policy_id)
	processDesingerShape.prop('bussinessObjectName','')
	if(stepProperties.bussinessObjectName)
		processDesingerShape.prop('bussinessObjectName',stepProperties.bussinessObjectName)	
	processDesingerShape.prop('step_data',{})
	if(stepProperties.step_data)
		processDesingerShape.prop('step_data',stepProperties.step_data)
	processDesingerShape.prop('stepname',type)
	if(stepProperties.stepname)
		processDesingerShape.prop('stepname',stepProperties.stepname)

	processDesingerShape.prop('edc_cell_type',constantObj.PROCESS_DESIGNER_EDC_CELL_TYPE)
	processDesingerShape.prop('is_embed',true)
	manageShapeIcon(processDesingerShape,false)
	return processDesingerShape
}

export function getDesignerShapes(){
	return [getDataSelectionShape(),getMinusShape(),getMergeShape(),getArchiveShape(),getCopyShape(),
	getPurgeShape(),getProcedureShape(),getDuplicateShape()]
}

export function getProcessDesignerWrapper(text,position){
	let wrapperRect = edcWrapperRect.clone()

	// -5 for more magnet space, if still the complaint about magnet drawing, +(5/2) value into with 
	// position values so then text will get place at center.

	wrapperRect.resize(constantObj.PROCESS_DESIGNER_SHAPE_WIDTH, constantObj.PROCESS_DESIGNER_SHAPE_HEIGHT);
	
	wrapperRect.position(position.positionX,position.positionY)
	wrapperRect.attr('label/title',text)
	wrapperRect.attr('rect/title',text)
	let wrappedText = anonymousObj.getWrappedText(text,wrapperRect.size().width,joint,2,10)
	wrapperRect.attr('label/text',wrappedText)
	
	// wrapperRect.attr('label/text',tablesProps.table_description)
	wrapperRect.attr('label/refY', constantObj.PROCESS_DESIGNER_DESCRIPTION_REFY);
	wrapperRect.attr("rect/rx", constantObj.PROCESS_DESIGNER_SHAPE_BORDER_RX);
	wrapperRect.prop('edc_cell_type',constantObj.PROCESS_DESIGNER_EDC_CELL_CONTAINER_TYPE);
	wrapperRect.attr("root/magnet", false);
	wrapperRect.prop('step_name',text)
	wrapperRect.attr('label/font-size', 10);
	wrapperRect.attr('label/font-weight',"normal")
	return wrapperRect
}

export function manageShapeIcon(element,glow=false){
	if(!element)
		return
	let element_type = element.prop('steptype')
	switch(element_type){
		case constantObj.SELECTION_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getDataSelectionShape(glow)));
			break;
		case constantObj.MINUS_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getMinusShape(glow)));
			break;
		case constantObj.MERGE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getMergeShape(glow)));
			break;
		case constantObj.ARCHIVE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getArchiveShape(glow)));
			break;
		case constantObj.COPY_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getCopyShape(glow)));
			break;
		case constantObj.PURGE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getPurgeShape(glow)));
			break;
		case constantObj.PROCEDURE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getProcedureShape(glow)));
			break;
		case constantObj.DUPLICATE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getDuplicateShape(glow)));
			break;
		default:
			console.log('not handled element_type',element_type)
	}
	return element
}

var space_size = 10

// function Calculate_responsive_size() {
//     let stencil_paper_width = $('.card-body').width()
//     var one_tenth_part = stencil_paper_width / 10
//     space_size = (one_tenth_part * 1.5) / 3
//     // that.cell_width = (one_tenth_part * 8.5) / 3
// }

export function getEdcBOsForFlowchart(business_obj_list) {
    var cell_list = []
    let cell_width = 60
    var start_point = 10
    var current_space_size = start_point
    var y_on_stencil = 10
    for (var i = 0; i < business_obj_list.length; i++) {
        var formated_bus_name = business_obj_list[i].display_name.replace(/(?!$|\n)([^\n]{8}(?!\n))/g, '$1\n');
        // var cell = getRectangle(current_space_size, y_on_stencil, cell_width, 65, formated_bus_name)
        var cell = edcBORect.clone()
        cell.resize(80,60)
        cell.position(current_space_size, y_on_stencil)
        cell.attr('label/text',formated_bus_name)
        cell.attr('label/title', business_obj_list[i].display_name)
        cell.prop('attrs/rect/fill',"#B0B0B0")
        // cell.prop('attrs/text/fill',"#FFFFFF")
        cell.prop('is_bus_obj', true)
        cell.prop('bus_id', business_obj_list[i].object_id)
        cell.prop('bus_name', business_obj_list[i].object_name)
        cell.prop('policy_id', business_obj_list[i].policy_name)
        cell.prop('bus_version', business_obj_list[i].object_version)
        cell.attr('label/font-size', 10);
        current_space_size = current_space_size + cell_width + 30
        if (i > 0 && (i + 1) % 3 === 0) {
            current_space_size = start_point
            y_on_stencil = y_on_stencil + 90
        }
        cell_list.push(cell)
    }
    return cell_list
}