<template>
	<div>
		<v-row class="EDC-Row" no-gutters v-if="!hideHeading && bojson.object_name">
			<v-col cols="10" class="EDC-Col" align="Start">
			</v-col>
			<v-col cols="2" class="EDC-Col" align="end">
				<vc-button itemText="Print" @click.native="printObj"></vc-button>
			</v-col>
		</v-row>
		<v-row class="EDC-Row" no-gutters id="printdiv" ref="printdiv">
			<v-col cols="12" class="EDC-Col" v-if="bojson.object_name">
				<div style="overflow-x:auto;">
					<table id="objtable">
						<tr>
							<th>
								BUsiness Object Name: {{bojson.object_name}}
							</th>
							
							<th>
								Version: {{bojson.object_revision}}
							</th>
						</tr>
						<tr v-for="(relation,index) in bojson.revisedjoin" :key="'mainrel'+index">
							<td colspan="2">
								<table id="objtable" class="childtbl">
									<tr>
										<th>No. : {{index+1}}</th>
										<th colspan="2">Name: {{relation.relationshipName}}</th>
									</tr>
									<tr>
										<td>Type : {{relation.type}}</td>
										<td>Relation : {{relation.join_relationship}}</td>
										<td>Flattening : {{relation.is_flattening_allowed}}</td>
									</tr>
									<tr>
										<td colspan="3">Hierarchy : {{relation.hierarchyName}}</td>
									</tr>
									<tr>
										<td colspan="3">Flatten : {{relation.flatten_hierarchy}}</td>
									</tr>
									<tr v-if="relation.condition">
										<th class="firstSubHeader">Joins</th>
										<td colspan="2"> 
											<table id="objtable" class="childtbl">
												<tr v-for="(cond,condindex) in relation.condition" :key="'cond'+index+condindex">
													<td>{{cond.from_column}}</td>
													<td>{{getOperatorText(cond.operator)}}</td>
													<td>{{cond.to_column}}</td>
												</tr>
											</table>
										</td>
									</tr>
									<tr v-if="!hideRelFilter && relation.relationship_filter && relation.relationship_filter.length">
										<th>Rel.Filter</th>
										<td colspan="2"> 
											<table id="objtable" class="childtbl">
												<tr>
													<th>(</th>
													<th>Column</th>
													<th>Operator</th>
													<th>Value</th>
													<th>)</th>
													<th>Operation</th>
												</tr>
												<tr v-for="(filter,fitlerindex) in relation.relationship_filter" :key="'hfilter'+index+fitlerindex">
													<td>{{filter.s_parentheses}}</td>
													<td>{{filter.column_name}}</td>
													<td>{{getOperatorText(filter.operator)}}</td>
													<td>{{getValueText(filter)}}</td>
													<td>{{filter.e_parentheses}}</td>
													<td>{{getOperation(filter.operation)}}</td>
												</tr>
											</table>
										</td>
									</tr>
									<tr v-if="!hideJFromFilter && relation.jfromFilter && relation.jfromFilter.length">
										<th>JFrom Filter</th>
										<td colspan="2"> 
											<table id="objtable" class="childtbl">
												<tr>
													<th>(</th>
													<th>Column</th>
													<th>Operator</th>
													<th>Value</th>
													<th>)</th>
													<th>Operation</th>
												</tr>
												<tr v-for="(filter,fitlerindex) in relation.jfromFilter" :key="'jfrmfilter'+index+fitlerindex">
													<td>{{filter.s_parentheses}}</td>
													<td>{{relation.jto}}.{{filter.column_name}}</td>
													<td>{{getOperatorText(filter.operator)}}</td>
													<td>{{getValueText(filter)}}</td>
													<td>{{filter.e_parentheses}}</td>
													<td>{{getOperation(filter.operation)}}</td>
												</tr>
											</table>
										</td>
									</tr>
									<tr v-if="!hideJToFilter && relation.jtoFilter && relation.jtoFilter.length">
										<th>JTo Filter</th>
										<td colspan="2"> 
											<table id="objtable" class="childtbl">
												<tr>
													<th>(</th>
													<th>Column</th>
													<th>Operator</th>
													<th>Value</th>
													<th>)</th>
													<th>Operation</th>
												</tr>
												<tr v-for="(filter,fitlerindex) in relation.jtoFilter" :key="'jtofilter'+index+fitlerindex">
													<td>{{filter.s_parentheses}}</td>
													<td>{{relation.jto}}.{{filter.column_name}}</td>
													<td>{{getOperatorText(filter.operator)}}</td>
													<td>{{getValueText(filter)}}</td>
													<td>{{filter.e_parentheses}}</td>
													<td>{{getOperation(filter.operation)}}</td>
												</tr>
											</table>
										</td>
									</tr>
								</table>
							</td>
						</tr>					
					</table>
				</div>
			</v-col>
		</v-row>
		<loading-panel :loader="loader"></loading-panel>

	</div>
</template>
<script>
import {VALUE_TO_TEXT} from '@/methods/flowcharts/constants.js'
import { shapes, dia } from 'jointjs'
// import {generateGraphObj} from '@/methods/flowcharts/graph.js'
import { get as getToServer, deleteFromServer, post as postToServer } from '../methods/serverCall.js';
import config from '../config.json'
import * as graphObj from "@/methods/edcGraph/graph.js"

export default {
	name:'objectTabularView',
	data(){
		return{
			bojson:{"revisedjoin":{}},
			policyDetails:null,
			commitSize:null,
			dataSelectionSteps:['selection','merge','minus'],
			loader:false,

		}
	},
	props:{
		objectData:{
			type:Object
		},
		hideJFromFilter:{
			type:Boolean,
			default:false
		},
		hideJToFilter:{
			type:Boolean,
			default:false
		},
		hideRelFilter:{
			type:Boolean,
			default:false
		},
		hideHeading:{
			type:Boolean,
			default:false
		},
		hidePrint:{
			type:Boolean,
			default:false
		},
		objectdetails:{
			type:Object
		}
	},
	watch:{
		objectData:{
			handler(newvalue){
				this.bojson = {"revisedjoin":{}}
				this.manageBOjson(_.cloneDeep(newvalue))
			},
			deep:true
		},
		objectdetails:{
			handler(newvalue){
				this.bojson = {"revisedjoin":{}}
				this.getObject(_.cloneDeep(newvalue))
			},
			deep:true
		}
	},
	mounted(){
		if(this.objectData)
			this.manageBOjson(_.cloneDeep(this.objectData))
		else if(this.objectdetails)
			this.getObject(_.cloneDeep(this.objectdetails))
	},
	methods:{  
		getObject(data){
			let _this = this
			_this.loader = true
			let url = config.PUBLISHER_URL+'/published_object_details'
	  		postToServer(_this, url, data).then(Response => {
	  			_this.manageBOjson(Response)
	  			_this.loader = false

	        }).catch(objError => {
	        	_this.loader = false
	        })
    	},
		manageBOjson(boData){
    		let graph = new dia.Graph({},{ cellNamespace: shapes })
            let cells = graphObj.unzipGraph(this,{"cells":boData.object_json.graph_data.cells,"isAug2020ReleasedUI":boData.object_json.isAug2020ReleasedUI},'business_object')
	    	graph.fromJSON(JSON.parse(JSON.stringify(cells)));
	    	let rootEle =graph.getSources()[0]
	    	let element_list = graphObj.generateGraphObj(graph,rootEle,[rootEle.attributes])
	    	let objectJson = _.cloneDeep(boData)
	    	objectJson.object_json.cells = element_list
	    	this.bojson = objectJson
			this.manageRelations()
			console.log(this.bojson.revisedjoin)
    	},  	
		manageRelations(){
			this.bojson.revisedjoin =[]
			for(var i=0;i<this.bojson.object_json.business_data.joins.length;i++){
				let join = this.bojson.object_json.business_data.joins[i]
				let keys = _.keysIn(join)
				let revised_join = join[keys[0]]
				revised_join['is_flattening_allowed'] = this.isFlatteningAllowed(revised_join.has_flattening_restriction)
    		// add filter on JFROM table
    		let jfrom_id = revised_join.jfrom_id
    		let jfrom_filter = this.bojson.object_json.business_data.filters[jfrom_id]
    		revised_join['jfromFilter'] = []
    		if(jfrom_filter){
    			revised_join['jfromFilter'] = jfrom_filter.filter_rows_list
    		}
    		let jto_id = revised_join.jto_id
    		let jto_filter = this.bojson.object_json.business_data.filters[jto_id]
    		revised_join['jtoFilter'] = []
    		if(jto_filter){
    			revised_join['jtoFilter'] = jto_filter.filter_rows_list
    		}
    		revised_join['relationship_filter'] = []
    		let relationship_filter = this.bojson.object_json.business_data.link_filters[keys]
    		if(relationship_filter){
    			revised_join['relationship_filter'] = relationship_filter.filter_rows_list
    		}

    		revised_join['flatten_hierarchy'] = revised_join.hierarchyName
    		// here also need to check all parents filters
    		if(!jfrom_filter && !revised_join.has_flattening_restriction)
    		{
    			let hierarchyArray = revised_join.hierarchyName.split(' - ')
    			revised_join['flatten_hierarchy'] = hierarchyArray[0]+' - '+hierarchyArray[hierarchyArray.length-1]
    		}
    		this.bojson.revisedjoin.push(revised_join)
    	}
    },
    isFlatteningAllowed(restricted){
    	if(restricted)
    		return "False"
    	else
    		return "True"
    },
    getRevisedWorkTable(steplist,worktablename){
    	for(var i=0;i<steplist.length;i++){
    		if(steplist[i].stepname === worktablename)
    			return 'WT_'+(i+1)
    	}
    	return 'NA'
    },
    getDraggedtableList(table_list){
    	var tblString=''
    	for (var i = 0; i < table_list.length; i++) {
    		if(tblString)
    			tblString = tblString +','
    		tblString = tblString + table_list[i]
    	}
    	return tblString
    },
    getOutputColumns(columns,allsteps){
    	var colString=''
    	for (var i = 0; i < columns.length; i++) {
    		if(colString)
    			colString = colString+', '
    		let columnname = columns[i].column_name
    		if(columns[i].alias_name)
    			columnname = columns[i].alias_name
    		let tblname = columns[i].table_name
    		if(columns[i].is_drv_table === true || columns[i].is_drv_table==='true')
    			tblname= this.getRevisedWorkTable(allsteps,columns[i].table_name)
    		colString = colString + tblname +'.'+columnname
    	}

    	return colString
    },
    getFilterColumn(filter,allsteps){
    	if(filter.is_drv_table === false || filter.is_drv_table === 'false')
    		return filter.column_name
    	let colArray = filter.column_name.split('.')
    	if(colArray.length < 2)
    		return filter.column_name
    	return this.getRevisedWorkTable(allsteps,colArray[0])+'.'+colArray[1]
    },
    getOperatorText(operator){
    	return VALUE_TO_TEXT[operator]
    },
    getValueText(filter){
    	if(filter.operator === '_bet_')
    		return filter.value1 +' AND '+filter.value2
    	return filter.value1
    },
    getOperation(operation){
    	if(operation === false)
    		return "AND"
    	if(operation)
    		return "OR"
    	return ""
    },
    printObj(){
    	var printContents = document.getElementById("printdiv").innerHTML;
    	const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    	WinPrint.document.write(printContents+'<style type="text/css"> table { width:98% !important; cellpadding:10; } #objtable th,td{ text-align: left !important; padding-left: 5px !important; height: 30px !important; } .childtbl{ margin:10px !important; } tr:nth-child(even) {background-color: white;} table, td, th { border: 1px solid black !important; } #objtble .childtbl { width:98% !important; margin:1%; } .firstSubHeader { width:10%  !important; } </style>')
    	WinPrint.document.close();
		WinPrint.focus();
		WinPrint.print();
		WinPrint.close();
    }
}
}
</script>
<style scoped>
/* Whatever change made here, need to reflect in print function css */
table {
	width:98% !important;
	cellpadding:10;
}
#objtable th,td{
	text-align: left !important;
	padding-left: 5px !important;
	height: 30px !important;
}
.childtbl{
	margin:10px !important;
}
tr:nth-child(even) {background-color: white;}

table, td, th {
	border: 1px solid black !important;
}

#objtble .childtbl {
	width:98% !important; 
	margin:1%;
}

.firstSubHeader {
	width:10%  !important;
}
</style>
