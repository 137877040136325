import * as edcConstantObj from './constants.js'
import * as edcShapeObj from './shapes.js'
import * as edcLinkObj from './links.js'
import * as edcA2020Obj from './aug2020ReleaseGraphConversion.js'
import * as edcAnonymousObj from './anonymousFunctions.js'

var graphForBusinessObject = edcConstantObj.GRAPH_FOR_BUSINESS_OBJECT
var graphForProcessDesigner = edcConstantObj.GRAPH_FOR_PROCESS_DESIGNER
var graphForDataSelection = edcConstantObj.GRAPH_FOR_DATA_SELECTION

export function zipEdcGraph(graph,graphFor){
	let graphZip = []
	let elements = graph.getElements()

	// if element length = 2 and its an business object graph then it means only business object 
	// cell is there. So we need to execlude that
	if(!elements.length || (elements.length === 2 && graphFor=== graphForBusinessObject))
		return {"cells":graphZip}

	// we are seprating the elements and the link because while unzipping the graph we need to 
	// create elements first, then we can assign that element to the link as source/target 
	elements.forEach(function(element,index){
		if(element.prop('edc_cell_type') === edcConstantObj.BUSINESS_EDC_CELL_CONTAINER_TYPE 
			|| element.prop('edc_cell_type') === edcConstantObj.BUSINESS_OBJECT_KEYS_CONTAINER_TYPE 
			|| element.prop('is_rubber') || element.prop('edc_cell_type') === edcConstantObj.PROCESS_DESIGNER_EDC_CELL_CONTAINER_TYPE)
			return
		let zippedElement = zipElement(element,graphFor)
		graphZip.push(zippedElement)
	})

	let links = graph.getLinks()
	links.forEach(function(link,index){
		let zippedLink = zipLink(link,graphFor)
		graphZip.push(zippedLink)
	})
	return {"cells":graphZip}
}

export function zipElement(element,graphFor,bBox){
	// this function called from 2 places. for the graph which get designed from the new design , 
	// we will get bBox from the element else we will pass the bBox as parameter
	if(!bBox)
		bBox = element.getBBox()
	let zippedElement =  {
		"id":element.id,
		"positionX":bBox.x,
		"positionY":bBox.y,
		"is_zipped_element":true
	}
	if(graphFor === graphForBusinessObject || graphFor === graphForDataSelection){
		zippedElement['table_name']=element.prop('table_name')
		zippedElement['table_type']=element.prop('table_type')
		zippedElement['table_description']=element.prop('table_description')
		zippedElement['is_special_cell']=element.prop('is_special_cell')
		zippedElement['has_filter']=element.prop('has_filter')
	}
	if(graphFor === graphForProcessDesigner){
		zippedElement['stepname'] = element.prop('stepname')
		zippedElement['steptype'] = element.prop('steptype')
		zippedElement['bus_id'] = element.prop('bus_id')
		zippedElement['policy_id'] = element.prop('policy_id')
		zippedElement['bussinessObjectName'] = element.prop('bussinessObjectName')
		zippedElement['step_data'] = element.prop('step_data')
	}
	if(graphFor === graphForDataSelection){
			zippedElement['is_drv_table'] =  element.prop('is_drv_table')
	}
	return zippedElement
}


// is_zipped_link used alogn with type because we are not sure are we going to zip all links or not
export function zipLink(link,graphFor){
	let zippedLink = {
		'id':link.id,
		'type':'link',
		'source':{"id":link.source().id},
		'target':{"id":link.target().id},
		'is_zipped_link':true
	}
	if(graphFor ===graphForBusinessObject){
		zippedLink['label1'] = link.prop("labels/0/attrs/text/text")
		zippedLink['label2'] = link.prop("labels/1/attrs/text/text")
		zippedLink['has_flattening_restriction'] = link.prop("has_flattening_restriction")
	}
	return zippedLink
}

export function unzipGraph(that,graphData,graphFor){
	var cells = graphData.cells
	if(!graphData.isAug2020ReleasedUI){
		cells = edcA2020Obj.getRevisedCellsForAug2020Release(cells,graphFor,graphData.tableFilters)
	}
	let revised_cells = []
	cells.forEach(function(obj,index){
		if(obj.is_zipped_element){
			let unzipedElements =unzipElement(that,obj,graphFor)
			if(unzipedElements.length)
				unzipedElements.forEach(unzipedObj=>{
					revised_cells.push(unzipedObj)
				})
			else
				revised_cells.push(unzipedElements)
		} else if(obj.is_zipped_link){
			let unzipedLink = unzipLink(obj,graphFor,revised_cells)
			revised_cells.push(unzipedLink)
		}
		else
			console.log('not unzipped cell', obj)
	})
	return {"cells":revised_cells}
}

export function unzipElement(that,element,graphFor){
	let unzipedElements=[]
	let snapedPositionX = edcAnonymousObj.getNearestGridSnapPosition(element.positionX)
	if (snapedPositionX < 5)
		snapedPositionX = 5
	element.positionX = snapedPositionX
	let snapedPositionY = edcAnonymousObj.getNearestGridSnapPosition(element.positionY)
	if(snapedPositionY<5)
		snapedPositionY = 5
	element.positionY = snapedPositionY
	if(graphFor === graphForBusinessObject || graphFor === graphForDataSelection){
		if(graphFor === graphForDataSelection)
			element.addDrvProp = true
		if(element.is_special_cell){
			unzipedElements = edcShapeObj.getBusinessObjectKeysCell(element)
			that.businessObjectCell = unzipedElements[0]
			that.businessObjectCellWrapper = unzipedElements[1]
		}
		else{
			unzipedElements= edcShapeObj.getEdcTableShapeWithWrapper(element)
		}
	}
	else if(graphFor === graphForProcessDesigner){
		let shapePosition = {"positionX":element.positionX,"positionY":element.positionY}
		let actualShape = edcShapeObj.getProcessDesingnerShape(element.steptype,shapePosition
			,element)
		actualShape.attr('rect/magnet',true)
		let cellWrapper = edcShapeObj.getProcessDesignerWrapper(element.stepname,shapePosition)
		cellWrapper.embed(actualShape)
        return [actualShape,cellWrapper]

	}
	return unzipedElements
}

export function unzipLink(link,graphFor,unzipedCells){
	let source = _.find(unzipedCells,["id",link.source.id])
	if(!source)
		console.log('source not found for link')
	let target = _.find(unzipedCells,["id",link.target.id])
	if(!target)
		console.log('target not found for link')
	link['source'] = source
	link['target'] = target
	let unzipedLink = edcLinkObj.getDefaultLinkProperies(link)
	if(graphFor === graphForBusinessObject){
		unzipedLink.prop("labels/0/attrs/text/text",link.label1)
		unzipedLink.prop("labels/1/attrs/text/text",link.label2)
		unzipedLink.prop("has_flattening_restriction",link.has_flattening_restriction)
	}
	return unzipedLink
}


export function generateGraphObj(graph,element,elementlist){
	let neighbors = graph.getNeighbors(element,{outbound:true})
	if(!neighbors || !neighbors.length)
		return elementlist
	for (var i = 0; i < neighbors.length; i++) {
		elementlist.push(neighbors[i].attributes)
		generateGraphObj(graph,neighbors[i],elementlist)
	}
	return elementlist
}

